import env from "../env";
import React from 'react';
// import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import _ from 'lodash';
import Button1 from './Button1';
import InfoBox from './infoBox/InfoBox'
import alert from '../image/alert.svg'
import DownloadService from "../services/download-service"
import AuthService from "../Auth/auth";
// const fs = require('fs');

let type;
let report;
let key;
// let urlToHit;
// let rowOfArray = [];
let simsidArray = [];


class TwilioTable extends React.Component {
    constructor(props) {
		super(props);
		var today = new Date(),
			date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
		this.state = {
			date: date,
			isVerified: 'Not Calibarted',
			download: false,
			// devicetable: true,
			// floorplane: false,
			progress: 0

		};
		
        this.getSimsData = this.getSimsData.bind(this);
		this.getFleetData = this.getFleetData.bind(this);
		this.getBillingPeriod = this.getBillingPeriod.bind(this);
        this.getUsageData = this.getUsageData.bind(this);
		
	}

    Backfrompg() {
		console.log("we are out");
        window.location.reload(false);
	}

	async getSimsData(authKey) {
		let data = await fetch(`${env.base}/twilio-sims`, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + Buffer.from(authKey),}
        })
		let databody = await data.json(); 
        return databody; 
	}

    async getFleetData(authKey) {
        let data = await fetch(`${env.base}/sims-fleet`, {
			method: 'GET',
            headers: { 'Authorization': 'Bearer ' + Buffer.from(authKey),}
        })
        let dataBody = await data.json();
        return dataBody;
	}
	
	async getBillingPeriod(simsidArray, authKey) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", 'Bearer ' + Buffer.from(authKey));

		var raw = JSON.stringify(simsidArray);

		var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
		};

		const response = await fetch(`${env.base}/billing-period`, requestOptions);
		const result = await response.json();
		return result;
	}
	
	async getUsageData(simsidArray, authKey) {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", 'Bearer ' + Buffer.from(authKey));
		var raw = JSON.stringify(simsidArray);

		var requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: raw,
		redirect: 'follow'
		};

		const response = await fetch(`${env.base}/usage-record`, requestOptions);
		const result = await response.json();
		return result;
	}
	
	async chunkArray(array, chunkSize) {
    	const chunkedArray = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunkedArray.push(array.slice(i, i + chunkSize));
		}
    	return chunkedArray;
	}

	async mergeUsageRecords(data) {
		return data.map(item => {
			const sim_sid = item.sim_sid;
			const start_time = item.start_time;
			const end_time = item.end_time;
			const records = item.usageRecord;
			const length = records.length;
	  
			if (length === 0) {
				return {
					"sim_sid": sim_sid, "data_download": "", "data_upload": "", "data_total": "", "start_time": "", "end_time":""
				};
			} else {
				let data_download_sum = 0;
				let data_total_sum = 0;
				let data_upload_sum = 0;
	  
				for (let i = 0; i < length; i++) {
					let { data_download, data_total, data_upload } = records[i];
	  
					data_download_sum += data_download;
					data_total_sum += data_total;
					data_upload_sum += data_upload;
				}
	  
				// convert to megabytes (1 MB = 1000000 bytes)
				data_download_sum = (data_download_sum / 1000000);
				data_total_sum = (data_total_sum / 1000000);
				data_upload_sum = (data_upload_sum / 1000000);
	  
				return {
					"sim_sid": sim_sid, "data_download": data_download_sum, "data_upload": data_upload_sum, "data_total": data_total_sum, "start_time": start_time, "end_time": end_time
				}
			}
		});
	  }
   
	downloadCsv = (e) => {
		e.preventDefault();
		window.location = window.location.href
		const rows = [];
		let csvContent = null;

			if (type === "Twilio SIM") {
				rows.push('"Account","Location",SID,"Status","FleetName","DataProvisioned (MB)","DataDownloaded (MB)","DataUploaded (MB)","DataAvailable (MB)", DataConsumed (MB), Period Start Time, Period End Time, Percentage_of_data_consumed');
			}

		_.each(this.state.rowOfArray, function (data) {
			const rowString = Object.values(data).join(",");
			rows.push(rowString);
		});
	
		csvContent = rows.join("\n"); // Add a newline character here
		// DownloadService.downloadCsv(csvContent, `${this.props.akey ? 'UK' : (this.props.uskey ? 'US' : '')}_${this.props.type}_Report_${new Date().toDateString().replaceAll(' ', '_')}`);
		DownloadService.downloadCsv(csvContent, `${this.props.type}_${this.props.report}_Report_${new Date().toDateString().replaceAll(' ', '_')}`)
		this.props.setroute("Health", null, null);

		
	}

	componentDidMount = async () => { 

		type = this.props.type
		report = this.props.report
		key = await AuthService.fetchAuthKey()
		let numberOfSims = 100;
		let rowOfArray = [];
		let simApiData = [];
		let fleetApiData = [];
		// let usageApiData = [];
		// let billingPeriodApiData = [];

		let billingPeriod_result = [];
		let usage_records_result = [];


		
		simApiData = await this.getSimsData(key) || []
		fleetApiData = await this.getFleetData(key) || []

		let output = [];

		const simsData = simApiData.sims.map(sim => ({ ...sim }))	//SIMS API DATA
		simsidArray = simApiData.sims.map(sim => (sim.sid))	
		const fleetData = fleetApiData.fleets.map(fleet => ({ ...fleet }))	//FLEET API DATA

		
		const simsSidBatches = await this.chunkArray(simsidArray,numberOfSims);
		
		for (const batch of simsSidBatches) {
			const billingPeriodApiData = await this.getBillingPeriod(batch, key) || [];
			const usageApiData = await this.getUsageData(batch, key) || [];
			billingPeriod_result.push(billingPeriodApiData);
			usage_records_result.push(usageApiData);
		}
		const billing_period = billingPeriod_result.flat();
		const usage_record = usage_records_result.flat();

		

		const mergeBillUsage = billing_period.map(billing => {
		const usage = usage_record.find(u => u.simsid === billing.simsid);

			return {
				simsid: billing.simsid,
				start_time:   billing.data.length > 0 ? billing.data[0].start_time : "",
				end_time: billing.data.length > 0 ? billing.data[0].end_time : "",
				usage_records: usage ? usage.data : [],
			};
		});
		

		const usageRecordOnStartTime = mergeBillUsage.map(data => {
			const simSid = data.simsid;
			const length = data.start_time.length;
		  
			if (length !== 0) {
			  const billingPeriodStartTime = data.start_time;
			  const billingPeriodEndTime = data.end_time;
		  
			  const filteredUsageRecords = data.usage_records.filter(usageRecord => {
				const usageRecordStartTime = usageRecord.period ? new Date(usageRecord.period.start_time).getTime() : 0;
				return usageRecordStartTime >= new Date(billingPeriodStartTime).getTime();
			  });
		  
			  return {
				sim_sid: simSid,
				start_time: billingPeriodStartTime,
				end_time: billingPeriodEndTime,
				usageRecord: filteredUsageRecords,
			  };
			} else {
				return {
					sim_sid: simSid,
					start_time: '',
					end_time: '',
					usageRecord: data.usage_records,
				};
			}
		  });
	
		const mergedData = await this.mergeUsageRecords(usageRecordOnStartTime);
		
		output = [{
			simsData: simsData,
			fleetData: fleetData,
			usageData: mergedData
		}]

		const mappedAndMergedData = output[0].simsData.map(sim => ({
			...sim,
			fleetData: output[0].fleetData.find(fleet => fleet.sid === sim.fleet_sid) || {},
			usageData: output[0].usageData.find(usage => usage.sim_sid === sim.sid) || {},
		}));
		
		const array = mappedAndMergedData.map(data => ({
			
				ClientName: data.fleetData.unique_name? data.fleetData.unique_name.split(/\s+/)[0] : "--"  || data.fleetData.unique_name,
				Location: data.fleetData.unique_name? data.fleetData.unique_name.split(/\s+/).slice(1).join(" ") : "--" || data.fleetData.unique_name,
				SID: data.sid ? data.sid : "--",
				Status: data.status ? data.status : "--",
				FleetName: data.fleetData.unique_name ? data.fleetData.unique_name : "--",
				DataProvisioned: data.fleetData.data_limit ? data.fleetData.data_limit : "--",
				DataDownloaded: data.usageData.data_download ? Math.round(data.usageData.data_download * 100) / 100 : "--",
				DataUploaded: data.usageData.data_upload ? Math.round(data.usageData.data_upload * 100) / 100 : "--", 
				DataAvailable:  Math.round((data.fleetData.data_limit - data.usageData.data_total) * 100) / 100 || "--",
				DataConsumed: data.usageData.data_total ? Math.round(data.usageData.data_total * 100) / 100 : "--",
				StartTime: data.usageData.start_time ? data.usageData.start_time : "--",
				EndTime: data.usageData.end_time ? data.usageData.end_time : "--",
				Percentage_of_DataConsumed: (data.usageData.data_total / data.fleetData.data_limit) * 100 ? Math.round((data.usageData.data_total / data.fleetData.data_limit) * 100 * 100) / 100 : "No Usage Data Available"
			}))
				.filter(item => {
					if (report === "Consolidated") {
					  return item.Percentage_of_DataConsumed > 10; 		//For consolidated put condition update to 80
					} else {
					  return true;  	//For detailed include whole data
					}
				});
		
				const customOrder = ['active', 'inactive', 'new'];

				const sortedArray = array.sort((a, b) => customOrder.indexOf(a.Status) - customOrder.indexOf(b.Status));
						
					if (array.length > 0) {
						rowOfArray.push(...sortedArray);
					}
		
			this.setState({
				download: true, rowOfArray
			})
	}


	render() {
		return (
			<div>

				{/* {!this.state.download ? <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "gray" }}><i>Processing Data...</i></div> : <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>Report is ready to be downloaded</i></div>} */}
				{!this.state.download ? <InfoBox text={"Please Wait!  Preparing your report."} image={alert} background={'#ff5e5e1a'} /> : <InfoBox text={"Report is ready to be downloaded."} />}

				<ButtonToolbar className="justify-content-center" style={{ columnGap: 30 }}>
					{/* <button type="button" className="btn genericButton  btn-lg btn-danger rounded" style={{borderRadius:"10px", background:"linear-gradient(90deg, #F7707D 0%, #F29173 100%)" ,color:"#ffffff",borderColor:"transparent"}} onClick={() => this.Backfrompg()} ><i className="fas fa-arrow-circle-left"></i> Sign Out</button> */}
					{/* <button type="button" className="btn genericButton  btn-lg btn-success rounded" disabled={this.state.download == false} onClick={() => this.downloadCsv()}>Download {this.props.type} Report <i className="fas fa-map-marked-alt"></i></button> */}

					<Button1 onClick={(e) => this.downloadCsv(e)}
						text={`Download UK ${this.props.type} Report`}
						borderRadius={"10px"}
						background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
						color={"#ffffff"}
						disabled={!this.state.download ? true : false}
						style={{ cursor: !this.state.download ? "not-allowed" : "pointer", opacity: !this.state.download ? 0.1 : 1 }}
					/>

				</ButtonToolbar>


				{this.state.error && <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>{this.state.error}</i></div>}

			</div>

		)
	}
}

 export default TwilioTable;