// import env from "../env";
import React from 'react';
import Table from 'react-bootstrap/Table'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import _ from 'lodash';
import Button1 from './Button1';
import InfoBox from './infoBox/InfoBox'
import alert from '../image/alert.svg'
import DownloadService from "../services/download-service"
import AuthService from "../Auth/auth";
const fs = require('fs');

let SiteName = [];
let location = [];
// let outputHealth = [];
let AP_Name;
let type;
let report;
// eslint-disable-next-line no-unused-vars
let key;
let urlToHit;
// let rowOfArray=[];

const columnHeader = ["ClientName", "Location", "AP Name", "MAC", "Adopted", "Disabled", "Model","Type","Status"];

class APtable extends React.Component {
    constructor(props) {
		super(props);
		var today = new Date(),
			date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
		this.state = {
			date: date,
			isVerified: 'Not Calibarted',
			download: false,
			devicetable: true,
			floorplane: false,
			progress: 0

		};
		this.generateHeader = this.generateHeader.bind(this);
		this.generateTableData = this.generateTableData.bind(this);
		this.getSites = this.getHealth.bind(this);
		
	}

    Backfrompg() {
		console.log("we are out");
		window.location.reload(false);
	}

	async getSites(){
		console.log("in get tokens")
        let data = await fetch('https://l4m7g6uofb.execute-api.eu-west-1.amazonaws.com/dev/site', {
            method: 'GET',
            // headers: {'unifises': `${localStorage.getItem("unifises")}`, 'csrf_token': `${localStorage.getItem("csrf_token")}`}
        })
        let databody = await data.json(); 
		// console.log("databody",databody)
        return databody;  
	}
	
    // async getHealth(akey) {
	// 	const keyValue = akey;
	// 	let data = await fetch('https://l4m7g6uofb.execute-api.eu-west-1.amazonaws.com/dev/health', {
	// 		method: 'GET',
	// 		headers: { 'x-auth-key': 'eyJhbGciOiJSUzI1NiJ9.eyJ1dHAiOiJVIiwidHRwIjoiQUNDIiwic3ViIjoiMzA2NjUyIiwiYXVkIjoiaHR0cHM6Ly9hZG1pbi10ZW1wLmFmcmVlc3BhY2UuY29tIiwiZXhwIjoxNjk5ODg3OTY5LCJhaWQiOjEyLCJpYXQiOjE2OTkyMzk5Njl9.Ge7MS5k-lxeYIjEQAbo3D-8L6N7T6f5exjRwLByLnzj8dTK3_UCtPCsPd9DEaldWE0qNfJoxTyPu3E17_fjAcrHRmp0JBIbyXBkjd_YFf22-_BRUiDFdQq5L50mug1UNCeyw-QKHnkzgVf4N3FC68jisnuG0yjGMYr3-kCe2iXGZke-6SORl35h-lzHsJ60Ds4HsBLBkng333TlZVGgH5rbVO8tOeXnsG_HTRvTfcB6PDA1Sx6-HIa28275NNMaLFG4pHVqnb9WY7nz38vwUlu-_Ge3mzaCHu2xE-i4P2jJYRmExM46ZDbbGK8iDgU6r5x9kANhjCadrQtegUOIDAw' }
	// 	});
	// 	let dataBody = await data.json();
	// 	return dataBody;
	// }



	async getHealth() {

		console.log("getting sites")
		console.log(this.props.type, "--------", this.props.report)
		type = this.props.type
		report = this.props.report

		if (type === "AP") {
			console.log("there", type)
			urlToHit = "https://l4m7g6uofb.execute-api.eu-west-1.amazonaws.com/dev/health"
		}

		let akey = await AuthService.fetchAuthKey();
		let data = await fetch(urlToHit, {
			method: 'GET',
			headers: { 'x-auth-key': `${akey}`, "Access-Control-Allow-Origin": "*" }
		})
		let dataBody = await data.json();
		return dataBody;
	}

   
	downloadCsv = (e) => {
		e.preventDefault();
		window.location = window.location.href
		const rows = [];
		let csvContent = null;

		console.log("In downloadcsv");

		if (report === 'Consolidated') {
			if (type === "AP") {
				rows.push('"ClientName","Location","AP Name","MAC","Adopted","Disabled","Model","Type","Status"');
			}
			
		} else if (report === 'Detailed') {
			if (type === "AP") {
				rows.push('"ClientName","Location","AP Name","MAC","Adopted","Disabled","Model","Type","Status"');
			}
		}

		_.each(this.state.rowOfArray, function (data) {
			rows.push(data.join(","));
		});
	
		csvContent = rows.join("\n"); // Add a newline character here
		// console.log("csvContent",csvContent)
		// DownloadService.downloadCsv(csvContent, `${this.props.akey ? 'UK' : (this.props.uskey ? 'US' : '')}_${this.props.type}_Report_${new Date().toDateString().replaceAll(' ', '_')}`);
		DownloadService.downloadCsv(csvContent, `${this.props.type}_${this.props.report}_Report_${new Date().toDateString().replaceAll(' ', '_')}`)
		this.props.setroute("Health", null, null);

		
	}

    componentDidMount = async () => { // create the interval once component is mounted

		type = this.props.type
		console.log("type!!!!!",type)
		report = this.props.report
		key = this.props.akey || this.props.uskey
		// akey = us ? this.props.uskey : this.props.akey;
		
		let rowOfArray = [];
		// eslint-disable-next-line no-unused-vars
		let siteArray = [];
		let aoutput = [];
		let usoutput = [];

		// console.log("getting output", key)
		if (this.props.akey) {
			// console.log("getting output")
			siteArray = await this.getSites() || [];
			// aoutput = await this.getHealth(this.props.akey) || [];
			aoutput = await this.getHealth() || []
			// console.log(aoutput)
		}
		if (this.props.uskey) {
			// eslint-disable-next-line no-unused-vars
			siteArray = await this.getSites(true) || [];
			usoutput = await this.getHealth(this.props.uskey) || []; // true for us 
			// usoutput = await this.getHealth(true) || []
		}
		let output = [];
		try {
			output.push(...aoutput)
			// console.log("output",output)
		} catch (error) {
			console.error(error)
		}
		try {
			output.push(...usoutput)
		} catch (error) {
			console.error(error)
		}


 		
		
	for (var i = 0; i < output.length; i++) {
		var data = output[i].data.data; // Access the 'data' array within the 'data' object
		for (var j = 0; j < data.length; j++) {

			if(data[j].state === 1)
			{
				data[j].state = "Connected";
			}
			else if(data[j].state === 6){
				data[j].state = "Heart Beat Missed";
			}
			else{
				data[j].state = "Disconnected"
			}
			
		}
	}

		
		// eslint-disable-next-line no-unused-vars
		if(Array.isArray(output)){
			// eslint-disable-next-line no-redeclare
			for (var i = 0; i < output.length; i++) {
				// eslint-disable-next-line no-redeclare
				var data = output[i].data.data;
				// eslint-disable-next-line no-redeclare
				for (var j = 0; j < data.length; j++) {
					
					let array = [];
					if (report === "Consolidated") {
						
						if (data[j].state === 'Disconnected') {
							const spaceIndex = output[i].desc.indexOf(' ');
							// console.log(spaceIndex);
							try{
								if (spaceIndex !== -1) {
									const match = output[i].desc.match(/(\w+)(?:\s|_)/);
									if(match){
										const firstWord = match[1];
  										console.log(firstWord);
										
										array.push(firstWord)
									}	
								} else {
									array.push(output[i].desc); 
								}
							}
							catch(e){
								array.push(' ')
							}

							try{
								
								if(spaceIndex !== -1){
									var locationName = output[i].desc.substr(spaceIndex +1)

									if(locationName.includes('-')){
										array.push(locationName.replace(/-/g,''));
										
									}
									 else if(locationName.includes(',')){
										array.push(locationName.replace(/,/g,''))
									}
									else{
										array.push(locationName)
									}
								}
								else{
									array.push(' ')
								}
								
							}
							catch(e){
								array.push('')
							}

							try{
								array.push(data[j].name.replace(/,/, ' ')); // AP Name
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].mac); // mac
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].adopted); // adopted
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].disabled); // disabled
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].model); // model
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].type); // type
							} 
							catch (error){
								array.push('')
							}
						
							try{
								array.push(data[j].state); // state
							} 
							catch (error){
								array.push('')
							}
						
						}	
					}
					else if(report === "Detailed") {
						const spaceIndex = output[i].desc.indexOf(' ');
							console.log(spaceIndex);
							try{
								if (spaceIndex !== -1) {
									const match = output[i].desc.match(/(\w+)(?:\s|_)/);
									if(match){
										const firstWord = match[1];
  										console.log(firstWord);
										
										array.push(firstWord)
									}	
								} else {
									array.push(output[i].desc); 
								}
							}
							catch(e){
								array.push(' ')
							}

							try{
								
								if(spaceIndex !== -1){
									// eslint-disable-next-line no-redeclare
									var locationName = output[i].desc.substr(spaceIndex +1)

									if(locationName.includes('-')){
										array.push(locationName.replace(/-/g,''));
										
									}
									 else if(locationName.includes(',')){
										array.push(locationName.replace(/,/g,''))
									}
									else{
										array.push(locationName)
									}
								}
								else{
									array.push(' ')
								}
								
							}
							catch(e){
								array.push('')
							}

							try{
								array.push(data[j].name.replace(/,/, ' ')); // AP Name
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].mac); // mac
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].adopted); // adopted
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].disabled); // disabled
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].model); // model
							} 
							catch (error){
								array.push('')
							}

							try{
								array.push(data[j].type); // type
							} 
							catch (error){
								array.push('')
							}
						
							try{
								array.push(data[j].state); // state
							} 
							catch (error){
								array.push('')
							}
					}


					if (array.length > 0) {
						rowOfArray.push(array);
					}
				}
			}

			this.setState({
				download: true, rowOfArray
			})

		}
		else {
			this.setState({
				error: "There is an error in tim health api eg " + output.error
			})
		}	
	}

	excel(resp) {
		let csvContent;
		resp.forEach(function (rowArray) {
			let row = rowArray.join(",");
			csvContent += row + "\r\n";
		});
		fs.writeFile("Tim_health_report.csv", csvContent, function (err) {
			if (err) {
				return console.log(err);
			}
			console.log("The file was saved!");
		});
	}



	generateTableData() {
		let res = [];
		for (var i = 0; i < SiteName.length; i++) {
			for (var k = 0; k < AP_Name.length; k++) {
				res.push(
					<tr key={i + 0}>
						<td>{i + 1}</td>
						<td >{SiteName[i]}</td>
						<td>{location[i]}</td>
						<td>
							<td>{AP_Name[k]}</td>
						</td>
					</tr >
				)
			}
		}
		return res;
	}
	generateHeader() {
		let res = [];
		for (var i = 0; i < columnHeader.length; i++) {
			res.push(
				<th key={columnHeader[i]}>{columnHeader[i]}</th>)
		}
		return res;
	}


    devicetablelist() {
		return (
			<div >

				<Table bordered hover style={{ marginTop: 10, marginBottom: 10 }}>
					<caption>List of devices</caption>
					<thead className="thead-dark">
						<tr >
							{this.generateHeader()}
						</tr>
					</thead>
					<tbody >
						{this.generateTableData()}
					</tbody>
				</Table>


			</div >
		)
	}



	render() {
		// eslint-disable-next-line no-unused-vars
		const { devicetable, floorplane } = this.state
		return (
			<div>

				{/* {!this.state.download ? <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "gray" }}><i>Processing Data...</i></div> : <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>Report is ready to be downloaded</i></div>} */}
				{!this.state.download ? <InfoBox text={"Please Wait!  Preparing your report."} image={alert} background={'#ff5e5e1a'} /> : <InfoBox text={"Report is ready to be downloaded."} />}

				<ButtonToolbar className="justify-content-center" style={{ columnGap: 30 }}>
					{/* <button type="button" className="btn genericButton  btn-lg btn-danger rounded" style={{borderRadius:"10px", background:"linear-gradient(90deg, #F7707D 0%, #F29173 100%)" ,color:"#ffffff",borderColor:"transparent"}} onClick={() => this.Backfrompg()} ><i className="fas fa-arrow-circle-left"></i> Sign Out</button> */}
					{/* <button type="button" className="btn genericButton  btn-lg btn-success rounded" disabled={this.state.download == false} onClick={() => this.downloadCsv()}>Download {this.props.type} Report <i className="fas fa-map-marked-alt"></i></button> */}

					<Button1 onClick={(e) => this.downloadCsv(e)}
						text={`Download ${this.props.akey ? 'UK' : (this.props.uskey ? 'US' : '')} ${this.props.type} Report`}
						borderRadius={"10px"}
						background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
						color={"#ffffff"}
						disabled={!this.state.download ? true : false}
						style={{ cursor: !this.state.download ? "not-allowed" : "pointer", opacity: !this.state.download ? 0.1 : 1 }}
					/>

				</ButtonToolbar>


				{this.state.error && <div style={{ textAlign: "center", paddingBottom: "15px", fontSize: "25px", color: "red" }}><i>{this.state.error}</i></div>}

			</div>

		)
	}
}

 export default APtable;